// @flow
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import get from 'lodash/get'
import {
  Button,
  Modal,
  Card,
  Loader,
  PageWrap,
  SectionHeader,
  Heading,
  Image,
} from '@mlcl-digital/mlcl-design'
import MarketingBanner from '@mlcl-digital/mlcl-design/lib/base/MarketingBanner'
import LinkComponent from '@mlcl-digital/mlcl-design/lib/base/Link'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RichText } from '@sitecore-jss/sitecore-jss-react'

// actions
// eslint-disable-next-line max-len
import { getPolicySummary as getPolicySummaryActionHandler } from '../../../actions/creators/customerPolicies'
import { actionCreators } from '../../../actions'

// components.
import Header from '../../molecules/PageHeader'
import ArticleCardComponent from '../../molecules/ArticleCard'
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
import BenefitComponent from './Benefits'
import CustomerConciergeComponent from '../CustomerConcierge'
import NotificationBar from '../../molecules/NotificationBar'
import CustomerPolicyAnniversary from '../CustomerPolicyAnniversary'
import { TileContainer } from '../ContentCard'
import Tile from '../ContentCard/components/Tile'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { isFeatureEnabledForCP } from '../../../utils/commonUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'
import { createEvent } from '../../../utils/telemetry'

// constants
import { THEME_LIGHT } from '../../../constants/themes'
import { CUSTOMER_PERSONAL_DETAILS } from '../../../constants/routes'

// styles
import styles from './customerDashboard.styles'

// selectors
import { getIsPremiumCalculatorEligible } from '../../../selectors/premiumCalculator'
import { getCustomerBenefits } from '../../../selectors/customerDashboard'
import { shouldShowCommsModal, getUserId } from '../../../selectors/common'
import { getIsAltsEligible, getIsAllAltsRules } from '../../../selectors/alterations'

const BenefitsPanel = styled('div')(styles.benefitsPanel)
const BenefitsheadingContainer = styled('div')(styles.benefitsHeaderContainer)
const BenefitsContainer = styled('div')(styles.benefitsContainer)
const ArticleListWrapper = styled('div')()
const Loading = styled(Loader)(styles.loaderWrap)
const ArticleCardWrapper = styled('div')(styles.articleCardWrapper)
const ModalFooter = styled('div')(styles.modalFooter)
const ModalContent = styled('div')(styles.modalContent)
const SpeakToAnAdviserBtn = styled(Button)(styles.button)
const AdviserNoPolicyInforce = styled('div')(styles.advisernopolicyinforce)
const PremiumCalculator = styled(LinkComponent)(styles.premiumCalculatorLink)
const AssessYourNeeds = styled('div')(styles.assessYourNeeds)
const AccessNeedsImageWrapper = styled('div')(styles.accessNeedsImageWrapper)
const PageWrapWrapper = styled(PageWrap)(styles.pageWrapWrapper)

type CustomerDashBoardProps = {
  // Sitecore authorable fields.
  fields: Object,
  // knowledge base data
  knowledgeBase: Array<{
    articles: Array,
    categories: Array,
    totalPage: Number,
    pageIndex: Number,
    totalNumberOfRecords: Number,
    isFetchingArticles: Boolean,
  }>,
  // An object containing action creator functions.
  actions: {
    resetNavigationType: Function,
    resetFooterType: Function,
    getPolicyDetails: Function,
    changeNavigationType: Function,
    getMasterList: Function,
    setActiveArticleId: Function,
    setActiveCategoryType: Function,
    getAllArticles: Function,
    setAlterationsPolicies: Function,
    getAlterationRules: Function,
    getEscalation: Function,
  },
  // loggedIn customer details
  customerPersonalDetails: Object,
  // masterList details from sitecore
  masterList: Object,
  benefits: Array<{
    policyId: String,
    benefitAssured: String,
    description: Object,
    sumAssured: Number,
  }>,
  history: Object,
  showDigitalComms: Boolean,
  userId: String,
  hasSummaryLoaded: Boolean,
  // if alts eligible to show policy anniversary card
  isAltsEligible: Boolean,
  // component params from sitecore
  params: {
    'hide-policy-anniversary-card': 'true' | 'false',
  },
  isPremiumCalculator: boolean,
  isAllAltsRules: boolean,
  hasCustomerPolicies: boolean,
}

export class CustomerDashBoard extends Component<CustomerDashBoardProps> {
  constructor(props) {
    super(props)
    this.state = {
      showCommsModal: false,
    }
  }

  componentWillMount() {
    const {
      actions: { resetFooterType },
    } = this.props

    // set default footer
    resetFooterType()
    const tagEvent = createEvent({
      GA: { category: 'Customer lands on dashboard', action: 'CP - dashboard views' },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer lands on dashboard',
        },
      },
    })
    tagEvent.end()
  }

  componentDidMount() {
    const {
      actions: { getAllArticles },
      showDigitalComms,
    } = this.props

    getAllArticles({
      portal: 'Customer',
      categoryType: '',
      pageIndex: 1,
      noOfRecords: 3,
      articleId: '',
      searchText: '',
    })

    if (showDigitalComms) {
      this.showDigiCommsModal()
    }
  }

  showDigiCommsModal = () => {
    this.setState({ showCommsModal: true })
  }

  handleCommsModalActions = redirectToPersonalDetails => {
    const {
      history,
      actions: { updateUserProfile },
      userId,
    } = this.props
    this.setState({ showCommsModal: false })
    updateUserProfile({ hasSeenDigiCommsNotification: true }, userId, null)
    if (redirectToPersonalDetails) {
      history.push(CUSTOMER_PERSONAL_DETAILS)
    }
  }

  handleNeedsAssessmentClick = () => {
    const tagEvent = createEvent({
      GA: { category: 'CP-Assessyourneeds', action: 'Assess your needs' },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer assess your needs',
        },
      },
    })
    tagEvent.end()
  }

  handleLearnMoreInfoHubClick = () => {
    const tagEvent = createEvent({
      GA: { category: 'CP-LearnMoreMoneySmart', action: 'Money Smart - Learn More' },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer clicks money smart learn more',
        },
      },
    })
    tagEvent.end()
  }

  handleSetActiveArticle = (articleId: String) => {
    const {
      actions: { setActiveArticleId, setActiveCategoryType },
    } = this.props
    const categoryType = ''
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
  }

  getArticleList = articleList => {
    const {
      knowledgeBase: { isFetchingArticles },
    } = this.props
    return isFetchingArticles ? (
      <Loading />
    ) : (
      <ArticleCardWrapper>
        {articleList.map(article => (
          <ArticleCardComponent
            key={article.id}
            title={article.title}
            articleId={article.id}
            categoryType={article.categoryType}
            imageSource={article.image}
            handleSetActiveArticle={this.handleSetActiveArticle}
            link={article.url}
          />
        ))}
      </ArticleCardWrapper>
    )
  }

  renderArticleListComponent = (articleList: Array) => {
    const { fields } = this.props
    return (
      Boolean(articleList && articleList.length) && (
        <Fragment>
          <SectionHeader
            heading={fields.cpDashboardArticleDetailsHeaderText.value}
            subHeading={fields.cpDashboardArticleDetailsSubHeaderText.value}
          />
          <ArticleListWrapper>{this.getArticleList(articleList)}</ArticleListWrapper>
        </Fragment>
      )
    )
  }

  renderBenefits = () => {
    const { fields, benefits } = this.props
    return benefits.map(benefit => (
      <BenefitComponent
        key={`${benefit.name}_${benefit.policyId}_${benefit.type}_${benefit.sumAssured}`}
        fields={fields}
        benefit={benefit}
      />
    ))
  }

  renderAssessYourNeedsSection = () => {
    const {
      fields: {
        AssessYourNeedsSectionHeading,
        AssessYourNeedsTileHeading,
        AssessYourNeedsTileDescription,
        AssesYourNeedsLink,
        AssessYourNeedsImage,
        LearnMoreLink,
      },
    } = this.props
    return (
      <>
        <SectionHeader heading={AssessYourNeedsSectionHeading} />
        <AssessYourNeeds>
          <div>
            <Heading variant="h4" size="LG">
              {renderTextField(AssessYourNeedsTileHeading)}
            </Heading>
            <p>
              {renderTextField(AssessYourNeedsTileDescription, true)}
              <span>
                <LinkComponent
                  variant="tertiary"
                  target={LearnMoreLink?.value.target}
                  onClick={() => this.handleLearnMoreInfoHubClick()}
                  size="small"
                  to={{ pathname: LearnMoreLink?.value.href }}
                >
                  {LearnMoreLink?.value.text}
                </LinkComponent>
              </span>
            </p>
            <LinkComponent
              variant="primary"
              target={AssesYourNeedsLink?.value.target}
              onClick={() => this.handleNeedsAssessmentClick()}
              size="small"
              to={{ pathname: AssesYourNeedsLink?.value.href }}
            >
              {AssesYourNeedsLink?.value.text}
            </LinkComponent>
          </div>
          <AccessNeedsImageWrapper>
            <Image src={AssessYourNeedsImage?.value?.src} alt={AssessYourNeedsImage?.value?.alt} />
          </AccessNeedsImageWrapper>
        </AssessYourNeeds>
      </>
    )
  }

  // Todo: Move Policy Anniversary logic under CustomerPolicyAnniversary
  render() {
    const {
      fields,
      customerPersonalDetails: {
        details: { firstName },
      },
      knowledgeBase,
      knowledgeBase: { isFetchingArticles },
      masterList,
      hasSummaryLoaded,
      isAltsEligible,
      params,
      benefits,
      isPremiumCalculator,
      isAllAltsRules,
      hasCustomerPolicies,
    } = this.props
    const { showCommsModal } = this.state
    const {
      heading,
      cpViewCoverDetailsBenefitsHeading,
      cpViewCoverDetailsPolicyBreakdownLink,
      cpSpeaktoanAdviserLink,
      cpadviserNoPolicyinforce,
      cpadviserNoPolicyinforceText,
      cpViewCoverDetailsUserSalutation,
      cpDashboardNotificationBarText,
      cpDashboardNotificationBarButtonLink,
      cpDashboardBannerImageUrl,
      cpDashboardBannerImageAlt,
      MarketingBannerHeaderImage,
      MarketingBannerImage,
      MarketingBannerHeading,
      MarketingBannerActionCTA,
      MarketingBannerDescription,
      MarketingBannerSettingsIcon,
      PremiumCalculatorLink,
    } = reduceAuthorableFields(fields)

    const { LatestUpdatesHeading, LatestUpdatesDescription, LatestUpdatesTiles } = fields
    const articleList = get(knowledgeBase, 'articles', [])
    const controlList = get(masterList, 'data.featureControlSwitch', [])
    const showDashboardNotificationBar = isFeatureEnabledForCP(
      controlList,
      'enableDashboardNotification'
    )
    const filteredTiles = LatestUpdatesTiles.filter(
      ({ fields: { TileName } }) =>
        !(TileName?.value === 'LifeInsuranceCalculator' && !isPremiumCalculator)
    )
    return (
      <Fragment>
        {showDashboardNotificationBar && (
          <NotificationBar
            isCustomer
            heading={cpDashboardNotificationBarText}
            buttonProps={cpDashboardNotificationBarButtonLink}
          />
        )}
        <Header
          theme={THEME_LIGHT}
          heading={{ value: `${cpViewCoverDetailsUserSalutation} ${firstName || ''}${heading}` }}
          subComponent={
            <CustomerConciergeComponent
              fields={fields}
              styleOverrides={{
                selectWrap: {
                  marginBottom: 40,
                },
              }}
            />
          }
          bigImage
          imageSrc={cpDashboardBannerImageUrl}
          imageAlt={cpDashboardBannerImageAlt}
        />
        <CustomerPortalLifeCycle loadingConstraints={[isFetchingArticles]}>
          <PageWrapWrapper>
            {(MarketingBannerHeading || MarketingBannerDescription) && (
              <MarketingBanner
                imageSourceProps={MarketingBannerSettingsIcon}
                headerImageProps={MarketingBannerHeaderImage}
                heading={MarketingBannerHeading}
                subheading={<RichText field={{ value: MarketingBannerDescription }} />}
                buttonProps={MarketingBannerActionCTA}
                bannerImageProps={MarketingBannerImage}
              />
            )}
            <CustomerPolicyAnniversary />
            {params['hide-policy-anniversary-card'] !== 'true' && isAltsEligible && (
              <div>
                <SectionHeader
                  heading={renderTextField(LatestUpdatesHeading)}
                  subHeading={renderTextField(LatestUpdatesDescription)}
                />
                <TileContainer alignVariation="left" shouldHide={!!get(filteredTiles, 'length', 0)}>
                  {get(filteredTiles, 'length', 0) > 0 &&
                    filteredTiles.map(tileData => (
                      <Tile
                        tileWidth={Math.max(100 / filteredTiles.length, 25)}
                        key={get(tileData, 'fields.TileText.value')}
                        isBenefitData={false}
                        fields={tileData.fields}
                        alignVariation="left"
                        bgVariation="light"
                      />
                    ))}
                </TileContainer>
              </div>
            )}

            <BenefitsPanel>
              <BenefitsheadingContainer>
                <SectionHeader heading={cpViewCoverDetailsBenefitsHeading} />
                <div>
                  {isPremiumCalculator && (
                    <PremiumCalculator
                      variant="secondary"
                      size="small"
                      to={PremiumCalculatorLink?.href}
                      disabled={!isAllAltsRules}
                    >
                      {PremiumCalculatorLink?.text}
                      {!isAllAltsRules && (
                        <Loader borderSize={2} spinnerSize={16} type="noHeight" />
                      )}
                    </PremiumCalculator>
                  )}
                  <LinkComponent
                    testid="benefit-policies-link"
                    variant="secondary"
                    size="small"
                    to="/policies"
                    disabled={!hasCustomerPolicies}
                  >
                    {cpViewCoverDetailsPolicyBreakdownLink}
                  </LinkComponent>
                </div>
              </BenefitsheadingContainer>
              {benefits.length ? (
                <BenefitsContainer>{this.renderBenefits()}</BenefitsContainer>
              ) : (
                hasSummaryLoaded && (
                  <Card
                    style={{
                      height: 'auto',
                      width: 'auto',
                      maxWidth: '24rem',
                      marginTop: '30px',
                    }}
                  >
                    <p>
                      <AdviserNoPolicyInforce>{cpadviserNoPolicyinforce} </AdviserNoPolicyInforce>
                      <advisernopolicyinforceText>
                        {cpadviserNoPolicyinforceText}
                      </advisernopolicyinforceText>
                    </p>
                    <a
                      style={{
                        textDecoration: 'none',
                      }}
                      href="https://moneysmart.gov.au/financial-advice/choosing-a-financial-adviser"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SpeakToAnAdviserBtn variant="secondaryWithTheme">
                        {cpSpeaktoanAdviserLink}
                      </SpeakToAnAdviserBtn>
                    </a>
                  </Card>
                )
              )}
            </BenefitsPanel>
            {isPremiumCalculator && this.renderAssessYourNeedsSection()}
            {this.renderArticleListComponent(articleList)}
          </PageWrapWrapper>
        </CustomerPortalLifeCycle>
        <Modal
          isOpen={showCommsModal && hasSummaryLoaded}
          onClose={() => this.handleCommsModalActions(false)}
          title="Communication preferences"
          footer={
            <ModalFooter>
              <Button onClick={() => this.handleCommsModalActions(true)} size="small">
                {renderTextField(fields.editContactDetailsButtonLabel)}
              </Button>
            </ModalFooter>
          }
        >
          <ModalContent>{renderTextField(fields.digiCommsModalContent)}</ModalContent>
        </Modal>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
      getPolicySummary: getPolicySummaryActionHandler,
    },
    dispatch
  ),
})

export const mapStateToProps = state => {
  const {
    customerPersonalDetails,
    masterList,
    navigation,
    knowledgeBase: {
      knowledgeBase: { isFetchingArticles },
      knowledgeBase,
    },
    customerPolicySummaryStatus,
    alterations,
    config,
    customerPolicies,
  } = state
  return {
    hasSummaryLoaded: customerPolicySummaryStatus.isLoading === 'LOADED',
    customerPersonalDetails,
    masterList,
    navigation,
    knowledgeBase,
    benefits: getCustomerBenefits(state),
    isFetchingArticles,
    showDigitalComms: shouldShowCommsModal(state),
    userId: getUserId(state),
    alterations,
    isAltsEligible: getIsAltsEligible(state),
    isPremiumCalculator: getIsPremiumCalculatorEligible(state),
    isAllAltsRules:
      // full rules API loaded
      getIsAllAltsRules(state),
    isSignInRedirectEnabled: isFeatureEnabled('SignInRedirect', config.FEATURES),
    hasCustomerPolicies: Object.keys(customerPolicies).length > 0,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDashBoard))
