import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Modal } from '@mlcl-digital/mlcl-design'
import LinkComponent from '@mlcl-digital/mlcl-design/lib/base/Link'

// constants
import { PC_SUBMIT } from '../../../../../constants/modal'

// types
import { Fields } from '../../../../../types/components/PremiumCalculatorSubmit'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'
import { Store } from '../../../../../types/store'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../utils/telemetry'

const PCSubmitModal = ({ fields }: { fields: Fields }) => {
  const dispatch = useDispatch()
  const modalState = useSelector((state: Store) => state.modal)

  // modal close(X) button
  const handleClose = () => {
    dispatch(actionCreators.hideModal(PC_SUBMIT))
  }

  // back to home click
  const handleBackToHomeClick = () => {
    const event = createEvent({
      GA: {
        category: 'PC-ROThankYoupopupBackToHome',
        action: 'Thank you popup Back to home	',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator thank you popup back to home',
        },
      },
    })
    event.end()
  }

  const { BackToHomeLink } = fields

  return (
    <Modal
      isOpen={modalState[PC_SUBMIT]?.isModalVisible}
      onClose={handleClose}
      title={fields.SubmitModalHeading}
      footer={
        <LinkComponent
          onClick={handleBackToHomeClick}
          variant="link"
          size="small"
          to={BackToHomeLink?.value?.href}
        >
          {BackToHomeLink?.value?.text}
        </LinkComponent>
      }
    >
      {renderTextField(fields.SubmitModalDetails, true)}
    </Modal>
  )
}

export default PCSubmitModal
